<template>
  <div class="query-page">
    <RouterMenu />
    <div class="main">
      <div class="left">
        <h3 class="left-subtitle">查询</h3>
        <!-- 搜索表单 -->
        <div class="search-form">
          <el-form :label-position="labelPosition" label-width="4vw" :model="searchForm">
            <el-form-item label="线路:" class="line-label">
              <el-select v-model="searchForm.lineId" placeholder="请选择线路" @change="getRobotByLineId()" clearable>
                <el-option v-for="(line, index) in lineList" :key="index" :label="line.lineName" :value="line.lineId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间:" id="startTime">
              <mark-date-picker
                ref="startDateSelector"
                v-model="searchForm.startDate"
                v-bind:has-photo-dates="hasPhotoDates"
                holder-text="开始日期"
                tag="startDate"
                @date-btn-click="datePickerBtnClick"
                @change-date="changeDate"
              ></mark-date-picker>
            </el-form-item>
            <el-form-item label="机器:" class="line-label">
              <el-select v-model="searchForm.robotId" placeholder="请选择机器" @change="getPhotoDate" clearable>
                <el-option v-for="(robot, index) in robotOptionByLine" :key="index" :label="robot.robotName" :value="robot.robotId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="结束时间:" id="endTime">
              <mark-date-picker
                ref="endDateSelector"
                v-model="searchForm.beginDate"
                v-bind:has-photo-dates="hasPhotoDates"
                holder-text="结束日期"
                tag="endDate"
                @date-btn-click="datePickerBtnClick"
                @change-date="changeDate"
              ></mark-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button class="kr-normal-button" @click="getInspectPhotoDirTree" v-if="$hasElement('sftp-btn-get')">
                查询
              </el-button>
            </el-form-item>
          </el-form>
        </div>

        <!-- 资源目录 -->
        <div class="source-tree">
          <h3 class="left-subtitle">资源目录</h3>
          <div class="tree">
            <el-tree
              :props="props"
              :data="dirTreeData"
              empty-text="没有符合查询条件的资源"
              show-checkbox
              @check-change="handleCheckChange"
              accordion
              @node-click="handleNodeClick"
              ref="tree"
              node-key="id"
              style="height: 62vh"
            ></el-tree>
          </div>

          <!-- 按钮组 -->
          <div class="download">
            <el-button class="kr-normal-button" title="同步照片至检测任务" @click="asyncBtn" v-if="false && $hasElement('sftp-btn-synchoronize')">
              同 步
            </el-button>
            <el-button class="kr-normal-button" @click="downLoadZip" title="下载照片" v-if="$hasElement('sftp-btn-download')">
              下 载
            </el-button>
            <el-button class="kr-normal-button" @click="deletePhoto" title="删除照片" v-if="$hasElement('sftp-btn-delete')">
              删 除
            </el-button>
          </div>
        </div>
      </div>

      <!-- 大图 -->
      <div class="right">
        <div class="toolBox" ref="toolBox" v-if="isShowToolBox">
            <el-checkbox v-model="isOpenDraw" v-if="isAllowDraw">开启绘图</el-checkbox>
            <el-button type="primary" v-if="isOpenDraw" class="clearCanvas kr-normal-button" @click="removeAllImage">清除画布</el-button>
            <div class="selectListContain" v-if="isOpenDraw">
            <label>选择绘画图形：</label>
            <el-select v-model="value" placeholder="请选择" class="selectList normal-select"  ref="selectList">
                <el-option
                v-for="item in selectList"
                :key="item.id"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>            
            </div>
            <div class="selectListContain" v-if="isOpenDraw">
            <label>选择删除图形：</label>
            <el-select v-model="currentValue" placeholder="请选择" class="deleteList normal-select"  ref="deleteList">
                <el-option
                v-for="item in deleteImgList"
                :key="item.id"
                :label="item.id"
                :value="item.id"
                >
                <span style="float: left">{{ item.id }}</span>
                <span style="float: right"><i class="el-icon-close" @click="deleteChosenImg(item, $event)"></i ></span>
                </el-option>
            </el-select>            
            </div>
        </div>
        <viewer v-if="currentImgSrc && !isOpenDraw" :images="imgsList" ref="viewer" style="width:100%;height:100%">
          <img v-for="img in imgsList" :src="img.photoUrl" :key="img.photoName" style="width:100%;height:100%" :class="img.photoUrl != currentImgSrc ? 'displayNone' : ''" loading="lazy" />
        </viewer>
        <img-draw v-else-if="isAllowDraw" ref="imgDraw" :value="value"></img-draw>
        <div v-else><p>请选择一张图片进行预览</p></div>
      </div>
    </div>

    <!-- 同步照片弹出框 -->
    <el-dialog class="my-dialog small-dialog" :close-on-click-modal="false" title="同步照片至检测任务" :visible.sync="asyncPhotoDialogVisible" width="20%" top="30vh">
      <el-form :model="asyncMissionForm" :rules="asyncMissionFormRules" ref="asyncMissionForm">
        <el-form-item label="任务名称" prop="errandName">
          <el-select v-model="asyncMissionForm.errandName" placeholder="选择或输入新的任务名" filterable allow-create @change="missionChange">
            <el-option v-for="item in missionList" :key="item.value" :label="item.errandName" :value="item.errandName"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="cancelAsync">取 消</el-button>
        <el-button class="save-button" @click="asyncPhoto('asyncMissionForm')">同 步</el-button>
      </span>
    </el-dialog>

    <!-- 下载图片弹窗 -->
    <download-dialog ref="download-dialog" :params="downLoadZipParams" :lineName="currentLineName"/>
  </div>
</template>
<script>
import { getCalendarDate, getInspectPhotoDir, getInspectPhoto, deletePhotos, getselectErrands, postSyncPhoto } from "@/http/queryApi.js";
import { getLineNames } from "@/http/homeApi.js";
import { getLineRobots } from "@/http/inspectionPlanApi.js";
import RouterMenu from "@/components/RouterMenu.vue";
import moment from "moment";
import MarkDatePicker from "../../components/photoDisplay/markDatePicker.vue";
import imgDraw from '../../components/imgDraw.vue'
import { mapMutations, mapState } from 'vuex';
import { md5Encryption2 } from "@/utils/js/md5.js"
import DownloadDialog from "@/components/query/DownloadDialog.vue";
export default {
  name: "query",
  components: {
    RouterMenu,
    MarkDatePicker,
    imgDraw,
    DownloadDialog
  },
  data() {
    return {
      /*
       * @Date 2022/06/28 16:35:11
       * 搜索表单信息
       */
      lineList: [],           // 可选线路
      robotOptionByLine: [],  // 所选线路对应的可选机器
      searchForm: {
        lineId: "",
        robotId: "",
        startDate: "",
        endDate: ""
      },
      hasPhotoDates: [],      // 含有资源的日期列表


      /*
       * @Date 2022/06/28 16:35:45
       * 资源目录信息
       */
      dirTreeData: [],        // 文件夹信息
      props: {                // 树结构映射属性
        label: "name",
        children: "childrens"
      },
      downLoadZipParams: {
        robotId: "",
        lineId: "",
        towerList: []
      },


      /*
       * @Date 2022/06/28 16:46:34
       * 大图信息
       */
      imgsList: [],          // 当前选择图片类型的最后一级图片列表
      currentImgSrc: "",     // 点击的图片的路径


      /*
       * @Date 2022/06/28 16:37:32
       * 画图信息
       */
      isShowToolBox: false,  // 是否显示绘画框
      isAllowDraw: false,   // 是否允许绘画
      isOpenDraw: false,    // 是否开启画图
      currentValue: '',     // 当前选中的已画的图案
      value: '',            // 当前选择项画的图案
      selectList: [{        // 可画图案选项
          id: 0,
          label: '请选择',
          value: 'choose'
      },{
          id: 1,
          label: '点',
          value: 'Point'
      }, {
          id: 2,
          label: '线',
          value: 'LineString'
      },{
          id: 3,
          label: '面',
          value: 'Box'
      }],


      /*
       * @Date 2022/06/28 16:52:46
       * 同步信息
       */
      asyncPhotoDialogVisible: false, // 同步照片弹出框
      missionList: [],                // 检测任务列表
      asyncMissionForm: {
        errandName: ""
      },
      asyncMissionFormRules: {
        errandName: [{ required: true, message: "请选择任务或输入新的任务名", trigger: "blur" }]
      },
      isNewErrand: false, //同步时是否新建任务
      labelPosition: "right",
      zipFile: "" // 压缩文件
    };
  },

  computed: {
    ...mapState(['deleteImgList', 'compressFile']),
    topInstance() {
      return window.getComputedStyle(this.$refs.toolBox).height;
    },

    /**
     * @Author KR0132
     * @Date 2022/09/29 14:59:15
     * @Description 计算当前线路名称
     */
    currentLineName() {
      if(this.lineList!=null && this.searchForm.lineId) {
        let currentLine = this.lineList.find(line =>  {
          return line.lineId==this.searchForm.lineId;
        });
        if(currentLine) {
          return currentLine.lineName;
        }
      }
    }
  },

  watch: {
    "searchForm.lineId": function() {
      this.getRobotByLineId();
    }
  },

  methods: {
    ...mapMutations(['clearDeleteImgList']),
    /**
     * @Author KR0288
     * @Date 2022/06/30 09:28:48
     * @Description 获取检测任务列表
     */
    getSelectErrandsList() {
      getselectErrands().then(res => {
        if (res.rows.length > 0) {
          this.missionList = res.rows;
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/28 15:52:48
     * @Description 获取线路可选列表
     */
    getQueryAllLines() {
      getLineNames().then(res => {
        this.lineList = res.data;
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/28 15:52:59
     * @Description 根据所选线路获取机器人
     */
    getRobotByLineId() {
      this.searchForm.robotId = "";
      getLineRobots({ lineId: this.searchForm.lineId }).then(res => {
        if (res.data == null) {
          this.$message.warning("当前线路没有关联机器人！");
        } else {
          this.robotOptionByLine = res.data;
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/28 15:53:09
     * @Description 处理日历上按键被触发的时间：YYYY-MM-DD
     */
    datePickerBtnClick(date) {
      if (this.searchForm.lineId === "") {
        this.$message.warning("请先选择线路！");
        return;
      }

      let params = {
        lineId: this.searchForm.lineId,
        dayOfMonth: date
      };

      getCalendarDate(params).then(res => {
        this.hasPhotoDates = res.data;
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/28 16:32:19
     * @Description 处理选择日历上的日期
     */
    changeDate(data) {
      if (data.tag === "startDate") {
        this.searchForm.startDate = data.date;
      } else if (data.tag === "endDate") {
        this.searchForm.endDate = data.date;
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/28 16:59:13
     * @Description 切换机器人查询有图片的日期
     */
    getPhotoDate() {
      this.hasPhotoDate = [];
      var line = this.lineList.find(item => {
        return item.lineId == this.searchForm.lineId;
      });
      let params = {
        lineId: line.lineId,
        dayOfMonth: moment().format("YYYY-MM-DD")
      };
      getCalendarDate(params).then(res => {
        if(res.code == "000000") {
          if (res.data.length > 0) {
            res.data.forEach(item => {
              if (!this.hasPhotoDate.includes(item)) {
                this.hasPhotoDate.push(item);
              }
            });
          }        
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/28 17:06:56
     * @Description 点击查询按钮获取目录结构
     */
    getInspectPhotoDirTree(node, resolve) {
      let params = {
        startDate: this.searchForm.startDate,
        endDate: this.searchForm.endDate,
        lineId: this.searchForm.lineId,
        robotId: this.searchForm.robotId
      };
      if (params.startDate == "") {
        this.$message.warning("请选择开始时间");
        return;
      } else if (params.endDate == "") {
        this.$message.warning("请选择结束时间");
        return;
      } else if (params.lineId == "") {
        this.$message.warning("请选择线路");
        return;
      } 
      /*
       * @Date 2022/09/02 17:26:18
       * 机器人是可选参数
       */
      // else if (params.robotId == "") {
      //   this.$message.warning("请选择机器人")
      //   return
      // }
      getInspectPhotoDir(params).then(res => {
        if (res.code == "000000") {
          this.dirTreeData = res.data;
          if (res.data.length == 0) {
            this.$message.warning("未查询到资源");
          }
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/28 17:08:09
     * @Description 目录勾选事件
     */
    handleCheckChange(data, checked) {
      // 1、当前选中的文件夹或文件
      data.isChecked = checked;

      // 2、获取到勾选的所有塔id,组成数组towerList
      let towerList = [];

      this.dirTreeData.forEach((tower, towerIndex) => {
        // 3、判断第一级是否选择
        if (tower.isChecked) {
          let towerObj = {
            towerId: tower.id,
            downloadFiles: []
          };
          tower.childrens.forEach((date, dateIndex) => {
            towerObj.downloadFiles.push({
              shootDate: date.name,
              photoType: "2",
              isTotal: true,
              photoIds: []
            });
          });
          if (towerObj.downloadFiles.length > 0) {
            towerList.push(towerObj);
          }
        } else {

          // 4、第一级没选中，说明选了二级菜单
          let towerObj = {
            towerId: tower.id,
            downloadFiles: []
          };
          tower.childrens.forEach((date, dateIndex) => {
            if (date.isChecked) {
              towerObj.downloadFiles.push({
                shootDate: date.name,
                photoType: "2",
                isTotal: true,
                photoIds: []
              });
            } else {

              // 5、二级菜单也没选的话说明勾选了三级菜单——可见光或红外线
              date.childrens.forEach((type, typeIndex) => {
                if (type.isChecked) {
                  if (type.name == "可见光") {
                    console.log("勾选可见光");
                    towerObj.downloadFiles.push({
                      shootDate: date.name,
                      photoType: "1",
                      isTotal: true,
                      photoIds: []
                    });
                  } else {
                    towerObj.downloadFiles.push({
                      shootDate: date.name,
                      photoType: "0",
                      isTotal: true,
                      photoIds: []
                    });
                  }
                } else {

                  // 6、三级菜单没选就说明选了四级菜单——具体图片
                  var photoObj = {
                    shootDate: date.name,
                    photoType: "0",
                    isTotal: false,
                    photoIds: []
                  };
                  if (type.childrens == null) {
                    return;
                  }
                  type.childrens.forEach((photo, photoIndex) => {
                    if (photo.isChecked) {
                      if (type.name == "可见光") {
                        photoObj.photoType = "1";
                        photoObj.photoIds.push(photo.id);
                      } else {
                        photoObj.photoType = "2";
                        photoObj.photoIds.push(photo.id);
                      }
                    }
                  });
                  towerObj.downloadFiles.push(photoObj);
                }
              });
            }
          });
          if (towerObj.downloadFiles.length > 0) {
            towerList.push(towerObj);
          }
        }
      });
      this.downLoadZipParams = {
        robotId: this.searchForm.robotId,
        lineId: this.searchForm.lineId,
        towerList: towerList
      };
    },

    /**
     * @Author KR0288
     * @Date 2022/06/28 17:25:56
     * @Description 点击类型时加载最后一级图片,点击具体图片展示该图片
     */
    handleNodeClick(data, node) {
      if (node.level === 3) {
        let params = {
          lineId: this.searchForm.lineId,
          date: node.parent.data.name,
          lineId: this.searchForm.lineId,
          towerId: node.parent.parent.data.id,
          visible: data.name == "可见光" ? 1 : 0
        };
        getInspectPhoto(params).then(res => {
          console.log(res.data);
          res.data.forEach(item => {
            item.name = item.photoName;
          });
          this.imgsList = res.data.map(async item => {
            item.photoUrl = await md5Encryption2(item.photoUrl);
            return item;
          });
          this.currentImgSrc = this.imgsList[0].photoUrl;
          this.$nextTick(() => {
            this.$refs["tree"].updateKeyChildren(node.data.id, res.data);
          });
        });

        // 判断是否是红外线照片,开启画图模式
        if(data.name == '红外线') {
          this.isAllowDraw = true;
        }

      } else if (node.level == 4) {
        console.log(node.parent.data.childrens);
        this.imgsList = node.parent.data.childrens;
        this.currentImgSrc = data.photoUrl;
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/30 09:30:00
     * @Description 点击下载按钮，进行预加载处理
     */
    downLoadZip() {
      if(!this.downLoadZipParams.towerList || this.downLoadZipParams.towerList.length==0) {
        this.$message({type:"warning", message: "请先选择需要下载的文件"});
      }else {
        this.$refs['download-dialog'].displaypDialog();
      }
    },


    /**
     * @Author KR0288
     * @Date 2022/06/30 09:34:59
     * @Description 删除图片
     */
    deletePhoto() {
      let params = this.downLoadZipParams;
      if (params.towerList.length == 0) {
        this.$message.warning("请先选择图片");
        return;
      }
      this.$confirm("此操作将删除选中所有图片, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        deletePhotos(params).then(res => {
          if (res.code === "000000") {
            this.$message({
              message: res.data,
              type: "success"
            });
            this.getInspectPhotoDirTree();
          } else {
            this.$message({
              message: res.data,
              type: "error"
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/30 09:36:49
     * @Description 点击同步按钮
     */
    asyncBtn() {
      let params = this.downLoadZipParams;
      if (params.towerList.length == 0) {
        this.$message.warning("请先选择图片");
        return;
      }
      this.asyncPhotoDialogVisible = true;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/30 09:36:57
     * @Description 取消同步
     */
    cancelAsync() {
      this.asyncPhotoDialogVisible = false;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/30 09:40:22
     * @Description 任务名称可以手写，也可以从列表中选择，所以需要判断是否新建任务
     */
    missionChange(m) {
      let is = this.missionList.find(item => {
        return item.errandName === m;
      });
      if (is === undefined) {
        this.isNewErrand = true;
      } else {
        this.isNewErrand = false;
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/30 09:39:08
     * @Description 同步照片到其他巡检任务
     */
    asyncPhoto(formName) {
      let params = this.downLoadZipParams;
      params.isNewErrand = this.isNewErrand;
      params.errandName = this.asyncMissionForm.errandName;
      this.$refs[formName].validate(valid => {
        if (valid) {
          postSyncPhoto(params).then(res => {
            if (res.code === "000000") {
              this.$message({
                message: res.data,
                type: "success"
              });
              this.asyncPhotoDialogVisible = false;
            } else {
              this.$message({
                message: res.data,
                type: "error"
              });
              this.asyncPhotoDialogVisible = false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/04/25 14:33:01
     * @Description 清除所有绘画的图形
     */
    removeAllImage() {
      this.$refs.imgDraw.removeAllImage();
    },

    /**
     * @Author KR0288
     * @Date 2022/04/25 14:33:17
     * @Description 清除指定图形
     */
    deleteChosenImg(value, e) {
      console.log(value);
      this.$refs.imgDraw.removeImage(value.Feature, value.id);
      this.$refs.imgDraw.removeImage(value.textFeature)
    }
  },

  mounted() {
    this.getQueryAllLines();
    // this.getSelectErrandsList();
  },

  beforeDestroy() {
    // this.clearDeleteImgList();
  }
};
</script>
<style lang="scss" scoped>
.displayNone {
  display: none;
}
.query-page {
  height: calc(100% - 5vh);
  padding: 0 2vw;
  color: #fff;
  .main {
    display: flex;
    justify-content: space-between;
    height: calc(100% - 8vh);
    margin-top: 2vh;

    .left {
      width: 25%;

      .left-subtitle {
        font-size: 0.7vw;
        font-weight: normal;
        margin: 0;
        padding: 1vh;
      }
      .source-tree {
        height: 62vh;
        .tree {
          height: 62vh;
          overflow-y: auto;
        }

        .download {
          margin-top: 1vh;
          text-align: center;
          .el-button {
            width: 5vw;
            height: 3.2vh;
            font-size: 0.7vw;
            padding: 0;
          }
        }
      }
    }
    .right {
      position: relative;
      width: 73%;
      border: 0.3vh solid rgba(0, 255, 234, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .toolBox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        width: 100%;
        height: 10%;
        padding: 0 20px;
        box-sizing: border-box;
        .clearCanvas,
        .selectListContain {
          margin-left: 20px;

          .selectList, 
          .deleteList {
            width: 60% !important;
          }
        }
      }
    }
  }
}

</style>
