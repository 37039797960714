<template>
  <div class="router-menu">
    <div class="router">
      <div class="router-item" :class="$route.path == route.path ? 'router-item-active' : ''" v-for="(route, index) in tabsRoutesList" :key="index" @click="goToPath(route.path)">
        <div class="top-left"></div>
        <div class="top-right"></div>
        <div class="bottom-left"></div>
        <div class="bottom-right"></div>
        {{ route.name }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
export default {
  name: "routermenu",
  components: {},
  data() {
    return {
      routeList: []
    };
  },

  computed: {
    ...mapState(['tabsRoutesList'])
  },

  methods: {
    /**
     * @Author KR0288
     * @Date 2022/07/27 13:26:19
     * @Description 跳转到对应路由
     */
    goToPath(path) {
      this.$store.commit("updateCurrentMenu", path);
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.router-menu {
  width: 40%;
  margin: 0 auto;
  padding-top: 2vh;
  .router {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    .router-item {
      width: 6.8vw;
      height: 2.6vh;
      line-height: 2.6vh;
      text-align: center;
      margin-right: 1.1vw;
      cursor: pointer;
      //   background-color: #17312e;// 颜色放在主题中
      font-size: 1.3vh;
      letter-spacing: 0.1vw;
      position: relative;
      .top-right {
        position: absolute;
        top: 0;
        right: 0;
        width: 0.8vh;
        height: 0.8vh;
        // border-top: 1px solid #00ffbf; // 颜色放在主题中
        // border-right: 1px solid #00ffbf; // 颜色放在主题中
      }
      .top-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 0.8vh;
        height: 0.8vh;
        // border-top: 1px solid #00ffbf;// 颜色放在主题中
        // border-left: 1px solid #00ffbf;// 颜色放在主题中
      }
      .bottom-left {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0.8vh;
        height: 0.8vh;
        // border-bottom: 1px solid #00ffbf;// 颜色放在主题中
        // border-left: 1px solid #00ffbf;// 颜色放在主题中
      }
      .bottom-right {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0.8vh;
        height: 0.8vh;
        // border-bottom: 1px solid #00ffbf;// 颜色放在主题中
        // border-right: 1px solid #00ffbf;// 颜色放在主题中
      }
    }
  }
}
</style>
