<template>
  <div>
    <el-dialog class="my-dialog2" title="下载图片" :visible.sync="showDialog" :close-on-click-modal="false"  width="30%" :before-close="handleCloseDialog">

      <el-steps :active="active" finish-status="success">
        <el-step title="计算文件"></el-step>
        <el-step title="打包文件"></el-step>
        <el-step title="下载文件"></el-step>
      </el-steps>
      <div v-if="active==1">
        <el-descriptions class="margin-top" :column="1" size="medium">
          <el-descriptions-item label="当前线路">{{lineName}}</el-descriptions-item>
          <el-descriptions-item label="预计数量">{{photoNumber}}（张）</el-descriptions-item>
          <el-descriptions-item label="预计大小">{{displayPacketSize}}</el-descriptions-item>
        </el-descriptions>
        </div>
        <span v-if="active==1" slot="footer" class="dialog-footer">
          <el-button class="cancel-button" @click="showDialog = false">取消</el-button>
        <el-button class="save-button" @click="compressDownloadFile">开始下载</el-button>
          </span>
      <div v-if="active==2">
        <div style="height: 10vh; width: 100%; "
          v-loading="isCompressing"
          element-loading-text="本次下载选中文件比较多，服务器正在努力加载数据中，请耐心等待..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)">
        </div>
      </div>
      <div v-if="active==3">
        <el-descriptions class="margin-top" :column="1" size="medium">
          <el-descriptions-item label="下载文件">{{zipFile}}</el-descriptions-item>
          <el-descriptions-item label="文件大小">{{displayPacketSize}}</el-descriptions-item>
          <el-descriptions-item label="下载用时">{{displayDownloadTime}}</el-descriptions-item>
          <el-descriptions-item label="下载进度"><el-progress class="my-progress-bar"  :text-inside="true" :stroke-width="16" style="width: 80%;" :percentage="downloadProgress"></el-progress></el-descriptions-item>
        </el-descriptions>
      </div>
      <span v-if="active==2 || active==3" slot="footer" class="dialog-footer">
          <el-button class="cancel-button" @click="cancelDownload">取消下载</el-button>
          <el-button class="save-button" @click="showDialog = false">后台下载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { predownloadPhotoZip, compressPhotoZip, downloadPhotoZip, cancelDownloadPhotoZip} from "@/http/queryApi.js";
import { mapState } from 'vuex';
import { saveAs } from "file-saver";
import axios from 'axios';

export default {

  name: "DownloadDialog",
  data() {
    return {
      isCompressing: false, // 是否正在下载
      showDialog: false, // 显示弹窗
      active: 0, // 步骤位置
      zipFile: "", //下载的压缩文件
      photoNumber: 0, //图片数量
      packetSize: 0, //压缩包体积
      timeout: 300000, //下载超时时间（5分钟）
      waitTime: 0,    // 压缩图片等待时间
      downloadProgress: 0, // 下载进度
      downloadTime: 0, // 下载耗时
      timer: null, // 下载进行定时器
      source: null, // 取消下载token
    }
  },
  props: {
    /*
     * @Date 2022/09/29 10:14:13
     * 下载图片请求参数
     */ 
    params: {
      type: Object,
      require: true,
    },

    /*
     * @Date 2022/09/29 10:14:25
     * 当前线路名
     */
    lineName: {
      type: String,
      default: () => {
        return "";
      },
      require: true,
    },


  },

  computed: {
    ...mapState(['compressFile']),

    /**
     * @Author KR0132
     * @Date 2022/10/09 18:37:37
     * @Description 格式化显示下载事件
     */
    displayDownloadTime: function() {
      if( this.downloadTime<=0) {
        this.downloadTime = 0;
        return "0 秒";
      }
       
      let hour = Math.trunc(this.downloadTime/3600);
      let minute = Math.trunc((this.downloadTime - hour*3600)/60);
      let second = Math.trunc(this.downloadTime - hour*3600 - minute*60);
      return `${hour} 时 ${minute} 分 ${second} 秒`;
    },

    /**
     * @Author KR0132
     * @Date 2022/10/10 10:59:13
     * @Description 压缩包大小
     */
    displayPacketSize: function() {
      if(this.packetSize/536870912 > 1 ) {
        return parseFloat(this.packetSize/1073741824 ).toFixed(2) + " GB";
      }else if(this.packetSize/1048576 > 1 ) {
        return parseFloat(this.packetSize/1048576 ).toFixed(2) + " MB";
      }else if(this.packetSize/1024 > 1 ) {
        return parseFloat(this.packetSize/1024 ).toFixed(2) + " KB";
      }else {
        return parseFloat(this.packetSize).toFixed(2) + " B";
      }
    }
      
  },

  watch: {

  /**
     * @Author KR0132
     * @Date 2022/09/29 08:53:20
     * @Description 压缩文件完成
     * 
     */
     "compressFile" : function() {
      if(this.zipFile && this.zipFile==this.compressFile) {

        /*
         * @Date 2022/09/29 10:40:13
         * 关闭进度条定时器
         */
         this.handleClearTimer();
         this.active = 3;
         let timeStamp = 0;
         this.source = axios.CancelToken.source();
        
        downloadPhotoZip({zipFile: this.zipFile}, (event)=>{
          // 下载进度回调函数
          if( timeStamp==0 ) {
            this.downloadTime = 0;
            this.downloadProgress = 0;
            timeStamp = Math.round(event.timeStamp/1000);
          }else {
            this.downloadTime = Math.round(event.timeStamp/1000) - timeStamp;
            this.downloadProgress = Math.floor(event.loaded/event.total*100);
          }
          
        }, this.source).then(res => {
            /*
             * @Date 2022/09/29 10:49:49
             * 下载完成时保存文件
             */
            let blob = new Blob([res], { type: "application/zip" });
            saveAs(blob, this.zipFile);
            this.handleClearDownloadFile(true);
          })
          .catch(err => {
            this.$message({type: "error", message: err})
          }).finally(() => {
            this.downloadTime = 0;
            this.downloadProgress = 0;
            this.source = null;
          })
      }
    }
  },

  methods: {

     /**
     * @Author KR0132
     * @Date 2022/09/29 10:51:48
     * @Description 处理打开弹窗
     */
     displaypDialog() {
        this.showDialog = true;
        if(!this.isCompressing) {
          this.downloadFilePrepare();
        }
    },

    /**
     * @Author KR0132
     * @Date 2022/09/29 10:51:48
     * @Description 处理关闭弹窗
     */
    handleCloseDialog() {
      this.showDialog = false;
    },
    
    /**
     * @Author KR0132
     * @Date 2022/09/29 10:54:18
     * @Description 处理关闭压缩进度定时器
     */
    handleClearTimer() {
      if(this.timer) {
        clearInterval(this.timer);
      }

      this.timer = null;
      this.waitTime = 0;
    },

    /**
     * @Author KR0132
     * @Date 2022/09/29 17:16:50
     * @Description 清空下载文件
     */
    handleClearDownloadFile(isDone) {
/*
        * @Date 2022/09/29 09:03:24
        * 清空下载文件标识
        */
        this.zipFile = "";
        this.$store.commit("saveCompressFile", "");

        if(isDone) {
          this.showDialog = false;
          this.active = 1;
        }
    },

    /**
     * @Author KR0132
     * @Date 2022/09/29 09:25:17
     * @Description 开始下载压缩文件
     */
    downloadFilePrepare() {
     
      this.active = 1;
      if (this.params.towerList.length == 0) {
        this.$message.warning("请先选择图片");
        return;
      }

      // 2、下载前，先进行预处理
      predownloadPhotoZip(this.params).then(res => {
        
        // 1、获取参数
        this.packetSize = res.data.packetSize;
        this.photoNumber = res.data.photoNumber;

        // 2、判断下载文件的大小
        if (this.packetSize >= 1073741824) {
          this.$alert(`当前一次性下载的图片总大小为：${parseFloat(this.packetSize/1024/1024/1024).toFixed(2)}GB 超过1GB，建议减少选中的图片分多次下载！`, {
            confirmButtonText: "确定",
            callback: () => {
              this.$message("取消下载！");
            }
          });
          this.showDialog = false;
        }
      });
    },

    /**
     * @Author KR0132
     * @Date 2022/09/29 10:23:50
     * @Description 开始压缩文件
     */
    compressDownloadFile() {

      this.handleClearDownloadFile(false);
      this.active = 2;
      compressPhotoZip(this.params).then(res => {
          if(res.code==="000000") {
            this.zipFile = res.data.file;
            this.isCompressing = true;
            this.timer = setInterval(() => {
              this.waitTime += 2000;
              if(this.waitTime >= this.timeout) {
                this.$message({type:"error", message:"下载文件等待超时！建议减少一次性下载的图片数量，分多次下载！"});
                this.handleClearTimer();
              }

            }, 2000);
          }else {
            this.$message({type: "error", message: res.data});
          }
        }).catch(err => {
          this.$message({type: "error", message: err});
          this.handleClearTimer();
        });

    },

    /**
     * @Author KR0132
     * @Date 2022/09/29 15:24:31
     * @Description 取消下载文件
     */
    cancelDownload() {

      this.$confirm('此操作将取消下载文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 调用axios cancelToken 取消下载
          if(this.source) {
            this.source.cancel("取消下载文件！");
          }
          
      cancelDownloadPhotoZip({zipFile: this.zipFile}).then(res => {
        this.$message({type: "success", message:"取消下载成功！"});
        this.handleClearTimer();
            this.handleClearDownloadFile(true);
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消操作'
          });          
      });
    }
  }
}

</script>

<style scoped>

</style>
