<!--
 * @Author: KR0288
 * @Date: 2022-04-21 15:03:06
 * @LastEditTime: 2022-04-26 16:17:31
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\components\imgDraw.vue
-->
<template>
  <div class="container">
      <div class="mapContain" ref="mapContain">
        <div id="map" ref="map"></div>      
      </div>
  </div>
</template>

<script>
import {Map, View} from 'ol'
import Draw, {createBox} from 'ol/interaction/Draw';
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import {Circle, Fill, Stroke, Style, Icon, Text} from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {defaults as defaultInteractions} from 'ol/interaction';
import { mapMutations, mapState} from 'vuex';
export default {
    props: ['value'],

    data() {
        return {
            // ol数据
            map: null,
            view: null,
            vectorSource: null,
            vectorLayer: null,  
            imgFeature: null,
            draw: null,
            pixelArray: [],     // 相对视口的像素坐标   
            coordinate: [],     // 经纬度坐标 
            drawNum: 0,         // 图形的个数

            imgData: null,      // ajax返回图片数据
            scale: 0,           // 图片放大倍数

            // 每个图形的相对于图片的百分比数据
            drawImgPos: [],
            
        }
    },

    computed: {
        ...mapState(['deleteImgList']),
        /**
         * @Author KR0288
         * @Date 2022/04/22 13:20:06
         * @Description 将像素坐标作为百分比坐标
         */
        pixelToPercentArray() {
            return this.pixelArray.map((item, index) => {
                switch(index) {
                    case 0:
                        // return (item / this.imgData.width).toFixed(2) * 100 + '%';
                        return (item / window.getComputedStyle(this.$refs.map).width.replace('px', '')).toFixed(2) * 100 + '%'
                    case 1:
                        // return (item / this.imgData.height).toFixed(2) * 100 + '%'
                        return (item / window.getComputedStyle(this.$refs.map).height.replace('px', '')).toFixed(2) * 100 + '%'
                    default: 
                        break;
                }
            })
        }
    },

    methods: {
        ...mapMutations(['addDeleteImgList', 'removeDeleteImgList', 'clearDeleteImgList', 'modifyDeleteImgList']),
        /**
         * @Author KR0288
         * @Date 2022/04/22 09:07:35
         * @Description 获取图片资料
         */
        getImageData() {
            // 模拟数据
            this.imgData = {
                width: 384,
                height: 288,
                imgSrc: 'red.png'
            }
        },

        /**
         * @Author KR0288
         * @Date 2022/04/24 16:19:36
         * @Description 在浏览器当前分辨率获取图片放大的大小
         */
        getImgScale() {
            let _this = this;
            let scale = window.getComputedStyle(_this.$refs.map).width.replace('px', '') / this.imgData.width;
            return scale
        },

        /**
         * @Author KR0288
         * @Date 2022/04/21 15:38:28
         * @Description 初始化图层并隐藏地图
         */
        initMap() {
            let _this = this;
            this.vectorSource = new VectorSource({wrapX: false});

            // 创建图片要素
            this.imgFeature = new Feature({
                geometry: new Point([113.24981689453125, 23.126468438108688]),
            });
            this.imgFeature.setStyle(new Style({
                image: new Icon({
                    src: require('../assets/imgs/common/' + _this.imgData.imgSrc),
                    scale: _this.getImgScale()
                }),
                zIndex: 0 
            }))

            // 添加图片要素
            this.vectorSource.addFeature(this.imgFeature);
            this.vectorLayer = new VectorLayer({
                source: this.vectorSource,
                style: this.getStyle,
            });

            // 初始化地图
            this.map = new Map({
                target: 'map',
                layers: [
                    this.vectorLayer
                ],
                view: new View({
                    center: [113.24981689453125, 23.126468438108688], 
                    projection: "EPSG:4326", 
                    zoom: 12,
                }),
                interactions: defaultInteractions({
                    dragPan: false,
                    doubleClickZoom: false,
                    mouseWheelZoom: false
                })
            })

            // 获取像素坐标
            this.map.on('click', (e) => {
                this.pixelArray = this.map.getEventPixel(e.originalEvent);
            })
        },

        /**
         * @Author KR0288
         * @Date 2022/04/21 15:38:58
         * @Description 获取画图图形的样式
         */
        getStyle(feature) {
            const styles = {
                'Point': new Style({
                    image: new Circle({
                        radius: 8,
                        fill: new Fill({
                            color: '#33ff00'
                        })
                    }),
                }),

                'MultiPoint': new Style({
                    image: new Circle({
                        radius: 3,
                        fill: new Fill({
                            color: '#33ff00'
                        })
                    }),
                }),

                'LineString': new Style({
                    stroke: new Stroke({
                        color: '#33ff00',
                        width: 3,
                    }),
                    zIndex: 1
                }),

                'MultiLineString': new Style({
                    stroke: new Stroke({
                        color: '#33ff00',
                        width: 3,
                    }),
                    zIndex: 1
                }),

                'Polygon': new Style({
                    stroke: new Stroke({
                        color: '#33ff00',
                        width: 3,
                    }),
                    fill: new Fill({
                        color: 'rgba(0, 255, 0, 0.1)',
                    }),
                    zIndex: 1
                }),

                'MultiPolygon': new Style({
                    stroke: new Stroke({
                        color: 'yellow',
                        width: 3,
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 255, 0, 0.1)',
                    }),
                    zIndex: 1
                })
            };
            return styles[feature.getGeometry().getType()]
        },

        /**
         * @Author KR0288
         * @Date 2022/04/21 15:46:11
         * @Description 添加画图交互
         */
        addDraw(type) {
            let _this = this;
            let geometryFunction;
            if(type == 'Box') {
                type = 'Circle';
                geometryFunction = createBox();
            }

            this.draw = new Draw({
                type: type,         // 绘制的几何图形的几何类型
                clickTolerance: 6,  // 点击公差
                source: this.vectorSource,
                geometryFunction: geometryFunction,
                wrapX: false,       // 草图在x水平方向平铺
            });

            this.draw.on('drawstart', this.drawStartHandler)
            this.draw.on('drawend', this.drawEndHandler);
            this.map.addInteraction(this.draw);
        },

        /**
         * @Author KR0288
         * @Date 2022/04/22 13:18:56
         * @Description 画图开始事件回调函数
         */
        drawStartHandler(e) {
            let _this = this;

            // 图形数目+1
            this.drawNum += 1;

            // 添加当前绘画了的图形进入可删除图形列表
            this.addDeleteImgList({
                id: this.drawNum,
                Feature: e.feature
            });

            // 获取画图当前要素的经纬坐标
            this.coordinate = e.feature.values_.geometry.flatCoordinates;

            // 创建描述位置的文本要素
            let coordinateTextFeature = null;
            if(e.type == 'Point') {
                coordinateTextFeature = new Feature({
                    geometry: new Point(this.coordinate)
                });
            } else {
                coordinateTextFeature = new Feature({
                    geometry: new Point([this.coordinate[0], this.coordinate[1]])
                })
            }

            setTimeout(() => {
                this.addCoordinateFeature(coordinateTextFeature, e.type, this.drawNum);

                // 把坐标添加进数组中
                this.drawImgPos[(this.drawNum - 1) + ''] = {
                    image: e.feature.getGeometry().getType(),
                    index: this.drawNum,
                }
                if(e.feature.getGeometry().getType() == 'Point') {
                    this.drawImgPos[this.drawNum - 1].pos = this.pixelToPercentArray;
                }else {
                    this.drawImgPos[this.drawNum - 1].firstPos = this.pixelToPercentArray;
                }
            }, 50)
        },

        /**
         * @Author KR0288
         * @Date 2022/04/22 15:46:09
         * @Description 画图结束事件回调函数
         */
        drawEndHandler(e) {
            setTimeout(() => {       
                // 把坐标添加进数组中
                if(e.feature.getGeometry().getType() != 'Point') {
                    this.drawImgPos[(this.drawNum - 1) + ''].lastPos = this.pixelToPercentArray;                    
                }
            }, 100)
        },

        /**
         * @Author KR0288
         * @Date 2022/04/22 15:56:50
         * @Description 添加坐标要素
         */
        addCoordinateFeature(feature, type, currentFeatureIdx) {
            // 调整坐标文本的位置，防止遮盖边框
            let offsetY = -20;
            if(type == 'drawend') {
                offsetY = 20
            }

            feature.setStyle(new Style({
                text: new Text({
                    textAlign: 'center',
                    textBaseline: 'middle',
                    font: 'normal 8px 微软雅黑',
                    offsetY,
                    scale: 2,
                    text: `(${currentFeatureIdx}): [${this.pixelToPercentArray[0]}, ${this.pixelToPercentArray[1]}]`,
                    stroke: new Stroke({color: 'white', width: 1})
                }),
                zIndex: 1
            }));

            this.vectorSource.addFeature(feature);  
            this.modifyDeleteImgList({
                id: this.drawNum,
                textFeature: feature
            })
            console.log(this.deleteImgList);
        },

        /**
         * @Author KR0288
         * @Date 2022/04/24 17:15:58
         * @Description 清除画布所有图形
         */
        removeAllImage() {
            this.vectorSource.clear();
            this.vectorSource.addFeature(this.imgFeature);
            this.drawImgPos = [];
            this.clearDeleteImgList();
        },

        removeImage(feature, id) {
            this.vectorSource.removeFeature(feature);
            if(id) {
                this.removeDeleteImgList(id);
                this.drawImgPos.forEach(item => {
                    if(item.index == id) {
                        this.drawImgPos.splice(this.drawImgPos.indexOf(item), 1);
                    }
                })
                console.log(this.drawImgPos);                
            }
            
        }


    },

    watch: {
        /**
         * @Author KR0288
         * @Date 2022/04/24 16:43:57
         * @Description 监听selectList的变化
         */
        value: function(newValue) {
            if(this.map) {
                this.map.removeInteraction(this.draw);
                if(newValue != 'choose') {
                   this.addDraw(newValue) 
                }
            }
        }
    },

    mounted() {
        this.getImageData();
        this.getImgScale();
        this.initMap();
        // TODO: 画图后尚未有功需求提供
    }
}
</script>

<style lang="scss" scoped>
    .container {
        width: 100%;
        height: 100%;

        .mapContain {
            height: 100%;
            #map {
                width: 100%;
                height: 100%;
                margin: 0 auto;
            }
        }
    }
</style>