import md5 from "md5";
import {
  getSecretApi
} from "@/http/analysisApi";
import store from "@/store"
/**
 * @Author KR0288
 * @Date 2022/09/26 14:30:07
 * @Description 使用md5加密密钥2
 */
 export const md5Encryption2 = async (str) => {
  let reg = /[^<>\/\\\|:""\*\?]+\.(bmp|jpg|jpeg|png|BMP|JPG|JPEG|PNG)$/;
  let fileName = str.match(reg)[0];
  let secret = "";
  if(fileName) {
    str = str.replace(fileName, encodeURIComponent(fileName));
    if (sessionStorage.getItem("imgSecret")) {
      secret = sessionStorage.getItem("imgSecret");
    } else {
      let { data, code } = await getSecretApi({});
      if (code === "000000") {
        sessionStorage.setItem("imgSecret", data.secret);
        secret = data.secret;
      } else {
        return str;
      }
    }
    return str + "?secret=" + md5(secret + fileName).toLowerCase();
  } else {
    return str;
  }
}