<template>
  <div>
    <el-date-picker
      @focus="getDataDay()"
      @change="selectedDate()"
      :picker-options="pickerOptions"
      type="date"
      popper-class="selectTime"
      :placeholder="holderText"
      v-model="currentDate"
      value-format="yyyy-MM-dd"
      clearable
    ></el-date-picker>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "markDatePicker",
  props: {
    // 绑定属性，并且初始化一个默认值
    hasPhotoDates: Array,
    tag: String,
    holderText: String,
    defaultDate: String
  },
  data() {
    return {
      index: "",            // 没什么作用，反正选择器只能选到一个，就是0
      yearBtn: "",          // 去年、明年按钮
      monBtn: "",           // 上一个月、下一个月按钮
      currentDate: "",      // 传递给父组件的年月，父组件用于获取含有资源的时间列表，传回来子组件
      pickerOptions: {      // 给有资源的日期添加绿色背景
        cellClassName: Date => {
          if (this.hasPhotoDates !== undefined && this.hasPhotoDates !== null) {
            if (this.hasPhotoDates.includes(moment(Date).format("YYYY-MM-DD"))) {
              return "has-photo-day";
            }
          }
        }
      }
    };
  },
  mounted() {
    this.currentDate = this.defaultDate;
  },
  methods: {
    /**
     * @Author KR0288
     * @Date 2022/06/28 15:56:35
     * @Description 初始化将当前日期返回给父组件
     */
    getDataDay() {
      this.$nextTick(() => {
        // 1、绑定和初始化只进行一次
        if (this.index === "") {
          this.index = document.querySelectorAll(".selectTime .el-icon-d-arrow-left").length - 1;

          // 2、获取去年、前一个月、后一个月、明年按钮
          let PrevYearBtn = document.querySelectorAll(".selectTime .el-icon-d-arrow-left");
          let PrevMonthBtn = document.querySelectorAll(".selectTime .el-icon-arrow-left");
          let NextMonthBtn = document.querySelectorAll(".selectTime .el-icon-arrow-right");
          let NextYearBtn = document.querySelectorAll(".selectTime .el-icon-d-arrow-right");
          
          // 3、监听按钮
          PrevYearBtn[this.index].addEventListener("click", this.ListenerBtn);
          PrevMonthBtn[this.index].addEventListener("click", this.ListenerBtn);
          NextYearBtn[this.index].addEventListener("click", this.ListenerBtn);
          NextMonthBtn[this.index].addEventListener("click", this.ListenerBtn);

          // 4、获取当前时间按钮2022、06
          this.yearBtn = document.querySelectorAll(".selectTime .el-date-picker__header .el-date-picker__header-label:nth-child(3)");
          this.monthBtn = document.querySelectorAll(".selectTime .el-date-picker__header .el-date-picker__header-label:nth-child(4)");
        }
      });

      // 5、初始化this.currentDate为空，所以返回当前时间
      this.$emit("date-btn-click", this.currentDate ? this.currentDate : moment().format("YYYY-MM-DD"));
    },

    /**
     * @Author KR0288
     * @Date 2022/06/28 16:21:16
     * @Description 日历上的按键被点击，将当前日历上的第一天返回到父组件
     */
    ListenerBtn() {
      let yearNumber = this.yearBtn[this.index].innerHTML.split(" ")[0];
      let monthNumber = this.monthBtn[this.index].innerHTML.split(" ")[0];
      let firstDate = yearNumber + "-" + monthNumber + "-01";
      console.log(firstDate);
      this.$emit("date-btn-click", firstDate);
    },

    /**
     * @Author KR0288
     * @Date 2022/06/28 16:23:20
     * @Description 选中日历上的日期，发送给父组件
     */
    selectedDate() {
      this.$emit("change-date", { date: this.currentDate, tag: this.tag });
    }
  }
};
</script>
