import axios from "./ajax.js";

/**
 * @Author KR0288
 * @Date 2022/05/18 13:44:04
 * @Description 获取巡检计划
 */
// TODO:
export const getCheckPlans = data => {
  return axios({
    url: `/eprdms/plan/get/single/date`,
    method: "post",
    data
  });
};

// eprdms/line/queryLineName
export const getqueryLineName = params => {
  return axios({
    url: `/eprdms/line/queryLineName`,
    method: "get",
    params
  });
};

//   eprdms/tower/selectTowers
export const getqueryselectTowers = params => {
  return axios({
    url: `/eprdms/tower/get`,
    method: "get",
    params
  });
};

// eprdms/plan/addPlan
/**
 * @Author KR0288
 * @Date 2022/05/18 13:45:54
 * @Description 新增巡检计划
 */
export const postAddPlan = data => {
  return axios({
    url: `/eprdms/plan/add`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:46:25
 * @Description 更新巡检计划
 */
export const postUpdatePlan = data => {
  return axios({
    url: `/eprdms/plan/update`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:46:45
 * @Description 删除计划任务
 */
export const getDeletePlan = params => {
  return axios({
    url: `/eprdms/plan/delete`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:47:44
 * @Description 新增巡检模板
 */
export const postAddPlanTemplate = data => {
  return axios({
    url: `/eprdms/plan/template/add`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:48:05
 * @Description 更新巡检模板
 */
export const postUpdatePlanTemplate = data => {
  return axios({
    url: `/eprdms/plan/template/update`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:49:11
 * @Description 获取巡检模板
 */
export const getPlanTemplate = params => {
  return axios({
    url: `/eprdms/plan/template/get`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:49:28
 * @Description 删除巡检模板
 */
export const getDeletePlanTemplate = params => {
  return axios({
    url: `/eprdms/plan/template/delete`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:51:34
 * @Description 获取巡检任务类型
 */
export const getInspectMode = params => {
  return axios({
    url: `/eprdms/dict/inspectionType`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:51:57
 * @Description 获取巡检任务状态
 */
export const getPlanStatus = params => {
  return axios({
    url: `/eprdms/dict/planStatus`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:52:12
 * @Description 获取巡检任务下发状态
 */
export const getPlanReadyStatus = params => {
  return axios({
    url: `/eprdms/dict/readyStatus`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:52:45
 * @Description 查询线路对应的机器列表
 */
export const getLineRobots = params => {
  return axios({
    url: `/eprdms/device/robot/get/names`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:53:46
 * @Description 查询巡检日历中包含巡检任务的日期
 */
export const getContainPlanDateInMonth = params => {
  return axios({
    url: `/eprdms/plan/get/calendar`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 13:56:49
 * @Description 模糊查询巡检任务
 */
export const fuzzyQueryPlans = data => {
  return axios({
    url: `/eprdms/plan/get/by/condition`,
    method: "post",
    data
  });
};
