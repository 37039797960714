import axios from "./ajax.js";

/**
 * @Author KR0288
 * @Date 2022/05/18 14:01:31
 * @Description 获取线路上的机器
 */
export const getRobotsByLineId = params => {
  return axios({
    url: `/eprdms/device/robot/get/by/line`,
    method: "get",
    params
  });
};

export const getqueryAllLines = data => {
  return axios({
    // url: `/eprdms/line/queryAllLines`,
    url: '/eprdms/line/get/simple',
    method: "post",
    data
  });
};


//
export const getCalendarDate = data => {
  return axios({
    url: `/eprdms/sftp/get/calendar`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:05:31
 * @Description 根据筛选条件显示巡检图片目录结构
 */
export const getInspectPhotoDir = params => {
  return axios({
    url: `/eprdms/sftp/get/inspect/photoDir`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:06:08
 * @Description 点红外或者可见光目录的时候查询到具体图片 ?robotId=1&towerId=0&date=2020-11-26&visible=1
 */
export const getInspectPhoto = params => {
  return axios({
    url: `/eprdms/sftp/get/inspect/photos`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:07:22
 * @Description 批量删除照片
 */
export const delInspectPhoto = params => {
  return axios({
    url: `/eprdms/sftp/delete`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:10:30
 * @Description 下载图片压缩包预处理
 */
export const predownloadPhotoZip = data => {
  return axios({
    url: "/eprdms/sftp/download/zip/prepare",
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:10:30
 * @Description 压缩文件请求
 */
 export const compressPhotoZip = data => {
  return axios({
    url: "/eprdms/sftp/compress/zip",
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:10:38
 * @Description 下载图片压缩包
 */
export const downloadPhotoZip = (data, downloadCb, source) => {
  
  return axios({
    url: "/eprdms/sftp/download/zip",
    method: "post",
    data,
    timeout: 0, // 此接口无超时
    onDownloadProgress: downloadCb, // 下载回调函数
    cancelToken: source.token   // 取消请求token
  });
};


/**
 * @Author KR0288
 * @Date 2022/05/18 14:10:38
 * @Description 取消下载图片包
 */
 export const cancelDownloadPhotoZip = data => {
  return axios({
    url: "/eprdms/sftp/cancel/download",
    method: "post",
    data,
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:15:31
 * @Description 删除巡检图片
 */
export const deletePhotos = data => {
  return axios({
    url: "/eprdms/sftp/delete",
    method: "delete",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:14:44
 * @Description 查询缺陷分析任务
 */
export const getselectErrands = params => {
  return axios({
    url: "/eprdms/errand/get/simple",
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 14:16:21
 * @Description 同步预览图片到FDFS
 */
export const postSyncPhoto = data => {
  return axios({
    url: "/eprdms/sftp/synchronize",
    method: "post",
    data
  });
};
